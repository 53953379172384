@import "./styles/shared.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.23.0/theme/dracula.css");

.header {
  grid-area: header;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: $white;
}

.header a {
  color: $white;
}

.footer {
  grid-area: footer;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 7px;
  color: $white;
}
.footer span {
  margin: 0px 15px;
}

.footer a {
  color: $white;
  margin: 0px 15px;
}

.menu {
  grid-area: menu;
  background-color: $grey5;
  overflow: auto;
  padding: 25px;
  border-right: 1px solid $grey3;
}

.main {
  grid-area: main;
  background-color: $grey5;
  overflow: auto;
}

.main-grid-container {
  background-color: $black;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70px auto 30px;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

.header .MuiAppBar-colorPrimary {
  color: $white !important;
  background-color: $black !important;
}

.main-tab {
  height:100%;
  padding: 25px;
}

.main-content {
  padding: 25px;
}

.main-bullet {
  font-weight: 500;
}

.graphiql-tab {
  height:100%
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.token-container {
  padding: 16px;
  position: relative;
}
.button-container .clipboard-confirm {
  vertical-align: text-top;
}
.clipboard-confirm {
  font-size: 16px;
  padding: 15px;
}

.copy-hidden {
  opacity: 0;
  transition: all 250ms linear 5s; // <- the last value defines transition-delay
}

.copy-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.notebooks {
  margin-top: 50px;
}

.notebook-link {
  margin-top: 15px;
}

.graphiql-container .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.graphiql-container .toolbar-menu-items {
  border-radius: 4px;
}

.graphiql-container .topBar {
  height: 50px;
}

.graphiql-container .doc-explorer-title-bar, .graphiql-container .history-title-bar {
  height: 50px;
  overflow-x: hidden;
}

.graphiql-container .toolbar-button {
  padding: 4.5px 18.5px;
  border-radius: 30px;
  border: 1.5px solid transparent;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: 0 0;
  color: #111;
  border-color: #ccc;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 500;
}

.graphiql-container .docExplorerShow, .graphiql-container .historyShow {
  font-weight: 500;
}

.button-container {
  padding: 16px 0px;
}

.button-container .ncss-btn-primary-dark {
  margin: 0px 8px;
}

.refresh-overlay {
  display: flex;
  position:absolute;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
  background:rgba(255,255,255,0.5);
  z-index: 10;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
