$black: #111111;
$near-black: #222222;
$white: #ffffff;
$grey1: #8d8d8d;
$grey2: #e6e6e6;
$grey3: #e5e5e5;
$grey4: #f5f5f5;
$grey5: #fafafa;
$success: #48bd1f;
$error: #fe0000;
$NDE-blue: #40fffd;
$volt: #e7fb4c;
